export enum FEATURES_NAMES {
  FF_HOMEPAGE_AUTOCOMPLETE = 'FF_HOMEPAGE_AUTOCOMPLETE',
  FF_HAS_WITHDRAWAL_PERIOD_BYPASS = 'FF_HAS_WITHDRAWAL_PERIOD_BYPASS',
  FF_SCRIPT_DATA_DOG = 'FF_SCRIPT_DATA_DOG',
  FF_SCRIPT_DIDOMI = 'FF_SCRIPT_DIDOMI',
  FF_SCRIPT_ZD_ASSETS = 'FF_SCRIPT_ZD_ASSETS',
  FF_SCRIPT_GOOGLE_TAG_MANAGER = 'FF_SCRIPT_GOOGLE_TAG_MANAGER',
  FF_HIDE_MIN_PRICES = 'FF_HIDE_MIN_PRICES',
  FF_ELD_WINTER_BREAK = 'FF_ELD_WINTER_BREAK',
  FF_SHOW_GAS_PRICE = 'FF_SHOW_GAS_PRICE',
  FF_HIDE_ALTERNATIVE_CONSUMPTION_LINK = 'FF_HIDE_ALTERNATIVE_CONSUMPTION_LINK',
  FF_PRO_JOURNEY = 'FF_PRO_JOURNEY',
  FF_CUSTOMER_SERVICE_ON_SATURDAY = 'FF_CUSTOMER_SERVICE_ON_SATURDAY',
  FF_SHOW_PREMIUM_PLUS_SUPPLIERS = 'FF_SHOW_PREMIUM_PLUS_SUPPLIERS',
  FF_CARBON_IMPACT_NOTIFICATION = 'FF_CARBON_IMPACT_NOTIFICATION',
  FF_QUOTATION_SAVED_AMOUNT_EXPLANATION = 'FF_QUOTATION_SAVED_AMOUNT_EXPLANATION',
  FF_CONTRACT_PARTNER_NEWSLETTER = 'FF_CONTRACT_PARTNER_NEWSLETTER',
  FF_CONTRACT_BIRTHDAY_GIFT_CARD = 'FF_CONTRACT_BIRTHDAY_GIFT_CARD',
  FF_CUSTOMER_SERVICE_EMAIL = 'FF_CUSTOMER_SERVICE_EMAIL',
  FF_CUSTOMER_SERVICE_PHONE = 'FF_CUSTOMER_SERVICE_PHONE',
  FF_HOMEPAGE_SHOW_PHONE_INPUT = 'FF_HOMEPAGE_SHOW_PHONE_INPUT',
}
