import Text from 'src/components/ui/typography/text/Text';
import Button from 'src/components/ui/button/Button';
import Row from 'src/components/ui/layouts/deprecated/row/Row';
import { useIntlMessage } from 'src/components/utils/LanguageProvider/intl.helpers';
import Heading from 'src/components/ui/typography/heading/Heading';
import Column from 'src/components/ui/layouts/deprecated/column/Column';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigateTo } from 'src/hooks/useNavigateTo';
import { makeSelectDevis } from 'src/hocs/WithDevis/selectors';
import { QuotationResponseType } from 'src/hocs/WithDevis/types';
import { handleChangeOffer, handleCloseModal } from './AgeLimitExcedeed.utils';

const MAXIMUM_USER_AGE = 26;

export const AgeLimitExceeded = () => {
  const { intlMessage } = useIntlMessage();
  const dispatch = useDispatch();
  const { navigateTo } = useNavigateTo();
  const quotation: QuotationResponseType = useSelector(makeSelectDevis());

  return (
    <>
      <Column bottom={20}>
        <Heading type="h3">
          {intlMessage('app.errorManager.ageLimitExceeded.heading', { age: MAXIMUM_USER_AGE })}
        </Heading>
        <Text type="l">
          {intlMessage('app.errorManager.ageLimitExceeded.text', { age: MAXIMUM_USER_AGE })}
        </Text>
      </Column>
      <Row>
        <Button
          data-testid="ageLimitExceededCloseButton"
          onClick={() => handleCloseModal({ dispatch, quotation })}
          label={intlMessage('app.errorManager.ageLimitExceeded.closeButtonLabel')}
        />
        <Button
          data-testid="ageLimitExceededChangeOfferButton"
          onClick={() => handleChangeOffer({ dispatch, quotation, navigateTo })}
          label={intlMessage('app.errorManager.ageLimitExceeded.changeOfferButtonLabel')}
        />
      </Row>
    </>
  );
};
