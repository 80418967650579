export const colors = {
  neutral: {
    100: '#F8FBFB',
    200: '#BCD7DC',
    600: '#1E4247',
    700: '#1D3538',
    800: '#132527',
    900: '#0F1A1C',
  },
  primary: {
    300: '#74F1E3',
    500: '#17E8D1',
    600: '#12B5A3',
  },
  secondary: {
    300: '#FEC867',
  },
  alternative: {
    1: '#99B4FF',
    2: '#FF6669',
    3: '#9BCFC9',
    4: '#2FB3C2',
  },
  black: {
    10: '#E6E6E6',
    15: '#D9D9D9',
    25: '#BFBFBF',
    50: '#808080',
    75: '#404040',
  },
  info: {
    50: '#91B5ED',
  },
  success: {
    50: '#2F6A2F',
    100: '#4FB055',
  },
  warning: {
    50: '#FDB935',
  },
  error: {
    50: '#EBA594',
  },
  concept: {
    gas: {
      300: '#79C4FB',
      400: '#53B7FF',
      500: '#3080DE',
      600: '#1463BF',
      900: '#002A74',
    },
    electricity: {
      300: '#68DEB4',
      400: '#4BD7A4',
      500: '#019A5A',
      600: '#007544',
      900: '#065035',
    },
  },
  themeType: 'dark',
};
