import { core } from 'src/theme/tokens/core';

const commonSemanticalTokens = {
  primary: {
    weaker: core.colors.primary[300],
    weak: core.colors.primary[500],
    strong: core.colors.primary[600],
    accent: core.colors.secondary[300],
  },
  interaction: {
    default: core.colors.primary[300],
    active: core.colors.primary[500],
  },
  neutral: {
    weakest: core.colors.neutral[100],
    weaker: core.colors.neutral[200],
    strong: core.colors.neutral[600],
    stronger: core.colors.neutral[700],
    strongest: core.colors.neutral[800],
    intense: core.colors.neutral[900],
  },
  special: {
    blur: core.blur,
    inverted: core.colors.neutral[100],
    transparent: 'transparent',
  },
  state: {
    error: core.colors.error[50],
    info: core.colors.info[50],
    success: core.colors.success[50],
    secondarySuccess: core.colors.success[100],
    warning: core.colors.warning[50],
    disabled: {
      weakest: core.colors.black[10],
      weaker: core.colors.black[25],
      weak: core.colors.black[50],
      strong: core.colors.black[75],
    },
  },
};

export const semantical = {
  breakpoints: { ...core.breakpoints },
  colors: {
    background: {
      ...commonSemanticalTokens,
      disabled: core.colors.black[75],
      onPrimary: core.colors.neutral[600],
    },
    border: {
      ...commonSemanticalTokens,
      disabled: core.colors.black[10],
    },
    text: {
      ...commonSemanticalTokens,
      onPrimary: core.colors.neutral[900],
      disabled: core.colors.neutral[200],
    },
  },
  fonts: { ...core.fonts },
  radius: {
    none: core.radius.none,
    weakest: core.radius['2xs'],
    weaker: core.radius.xs,
    weak: core.radius.s,
    strong: core.radius.m,
    stronger: core.radius.l,
    strongest: core.radius.xl,
    full: core.radius.full,
  },
  shadows: {
    none: core.shadows.none,
    weakest: core.shadows.xs,
    weaker: core.shadows.s,
    weak: core.shadows.m,
    strong: core.shadows.l,
  },
  sizes: {
    border: {
      weak: core.sizes['3xs'],
      strong: core.sizes['2xs'],
    },
    icon: {
      '2xs': core.sizes.xs,
      xs: core.sizes.xs,
      s: core.sizes.s,
      m: core.sizes.m,
      l: core.sizes.l,
      xl: core.sizes.xl,
      '2xl': core.sizes['2xl'],
      '3xl': core.sizes['3xl'],
      '4xl': core.sizes['4xl'],
    },
    images: {
      xs: core.sizes.l,
      s: core.sizes['3xl'],
      m: core.sizes['5xl'],
      l: core.sizes['6xl'],
      xl: core.sizes['9xl'],
    },
    layout: {
      maxContentWidth: '1088px',
      maxFooterWidth: '1260px',
      maxLayoutWidth: '1440px',
    },
    ...core.sizes,
  },
  spacing: { ...core.spacing },
  transitions: {
    ...core.transitions,
    duration: {
      slower: core.transitions.transitionDuration[1500],
      slow: core.transitions.transitionDuration[1000],
      fast: core.transitions.transitionDuration[300],
      faster: core.transitions.transitionDuration[150],
    },
  },
  zIndex: { ...core.zindex },
};
