import { QUOTATION_FIELDS } from 'src/hocs/WithDevis/constants';
import { PROMO_CODE_FIELDS } from 'src/hocs/WithPromoCode/constants';
import { POD_BY_ADDRESS_FIELDS } from 'src/hocs/WithPODInfo/constants';
import { ELIGIBILITY_FIELDS } from 'src/hocs/WithEligibilityNew/constants';

import { SouscrireFormKey } from './SouscrireNew.types';

export const PROFESSIONAL_URL_PARAMETER = '1';

/**
 * REDUX ACTIONS
 */
export const ON_USER_SUBMIT = 'app/Souscrire/ON_USER_SUBMIT';

// these are arbitrary literals since they are not API fields and are specific to the page
export const nonApiFields = {
  IS_ZIP_CODE_INPUT: 'isZipCodeInput',
  SELECTED_BILLING_ADDRESS: 'selectedBillingAddress',
} as const;

/**
 * This mapping is not a standard in the app but it increases
 * the resilience of the home page a lot, because we make many
 * references to the home page form fields, so it is much more
 * understandable to refer to it as such instead of referring
 * to it with its value (ie the corresponding API field)
 */
export const HOME_FORM_FIELDS = {
  STREET_NAME: POD_BY_ADDRESS_FIELDS.STREET_NAME,
  STREET_NUMBER: POD_BY_ADDRESS_FIELDS.STREET_NUMBER,
  ZIP_CODE: ELIGIBILITY_FIELDS.ZIP_CODE, // it is also used by SubscriptionTypeByAddress (correct mapping should be done in Saga)
  CITY: ELIGIBILITY_FIELDS.CITY, // it is also used by SubscriptionTypeByAddress (correct mapping should be done in Saga)
  CUSTOMER_TYPE: QUOTATION_FIELDS.CUSTOMER_TYPE,
  COMPANY_NAME: QUOTATION_FIELDS.COMPANY_NAME,
  SUPPLIER_INTERVENTION_TYPE: QUOTATION_FIELDS.SUPPLIER_INTERVENTION,
  SUPPLIER_DURATION: QUOTATION_FIELDS.SUPPLIER_DURATION,
  SUPPLIER_DATE: QUOTATION_FIELDS.SUPPLIER_DATE,
  PROMO_CODE: PROMO_CODE_FIELDS.CODE,
  IS_ZIP_CODE_INPUT: nonApiFields.IS_ZIP_CODE_INPUT,
  SELECTED_BILLING_ADDRESS: nonApiFields.SELECTED_BILLING_ADDRESS,
  FIRSTNAME: POD_BY_ADDRESS_FIELDS.FIRSTNAME,
  LASTNAME: POD_BY_ADDRESS_FIELDS.LASTNAME,
  USER_MOBILE_PHONE_NUMBER: 'mobileNumber',
} as const;

/**
 * Maps some Homepage inputs names to a visible element ID allowing
 * us to scroll to it. It is necessary to use useScrollToError
 * on the Homepage.
 */
export const HOME_INPUT_SCROLL_IDS: { [inputName in SouscrireFormKey]?: string } = {
  [HOME_FORM_FIELDS.SELECTED_BILLING_ADDRESS]: 'Input__Label__address',
  [HOME_FORM_FIELDS.SUPPLIER_INTERVENTION_TYPE]: 'supplierInterventionType_MES',
  [HOME_FORM_FIELDS.SUPPLIER_DATE]: 'supplierInterventionDate',
} as const;

export const ELD_IN_WINTER_BREAK_NON_ELIGIBLE = 'app/Souscrire/ELD_IN_WINTER_BREAK_NON_ELIGIBLE';

export const URL_PARAMS = {
  CODE_PROMO: 'codePromo',
  SUPPLIER_INTERVENTION_TYPE: 'supplierInterventionType',
  LAST_NAME: 'lastName',
  FIRST_NAME: 'firstName',
  MOBILE_PHONE: 'mobilePhone',
  ZIP_CODE: 'zipCode',
  STREET_NUMBER: 'streetNumber',
  STREET_NAME: 'streetName',
  CITY_NAME: 'cityName',
} as const;
