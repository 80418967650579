import { css } from '@emotion/react/macro';
import { tooltipTheme } from 'src/theme/tokens/components/tooltip';

export const thirdParties = css`
  /* Zendesk chatbox in left bottom corner of the application */

  #launcher {
    bottom: 118px !important;
    z-index: 999 !important;
  }

  #webWidget {
    bottom: 70px !important;
  }

  @media (min-width: 414px) {
    #launcher {
      bottom: 88px !important;
    }

    #webWidget {
      bottom: 70px !important;
    }
  }

  .tippy-popper {
    .tippy-tooltip {
      background-color: ${tooltipTheme.background};
      box-shadow: ${tooltipTheme.boxShadow};
      border-radius: ${tooltipTheme.radius};
      color: ${tooltipTheme.textColor};
      padding: ${tooltipTheme.padding};
    }

    &[x-placement='bottom'] {
      .arrow-regular[x-arrow] {
        border-bottom-color: ${tooltipTheme.background};
      }
    }

    &[x-placement='right'] {
      .arrow-regular[x-arrow] {
        border-right-color: ${tooltipTheme.background};
      }
    }

    &[x-placement='left'] {
      .arrow-regular[x-arrow] {
        border-left-color: ${tooltipTheme.background};
      }
    }

    &[x-placement='top'] {
      .arrow-regular[x-arrow] {
        border-top-color: ${tooltipTheme.background};
      }
    }
  }
}
`;
