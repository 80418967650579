import styled from '@emotion/styled/macro';
import Column from 'src/components/ui/layouts/deprecated/column/Column';
import Row from 'src/components/ui/layouts/deprecated/row/Row';
import { modalTheme } from 'src/theme/tokens/components/modal';
import { backDropTheme } from 'src/theme/tokens/components/backdrop';
import { ModalProps } from './Modal.types';

const { modal } = modalTheme;

export const ModalContainer = styled.div<{ isOpened: ModalProps['isOpened'] }>`
  align-content: center;
  align-items: center;
  background-color: ${backDropTheme.backgroundColor};
  backdrop-filter: blur(${backDropTheme.bluriness});
  display: ${({ isOpened }) => (isOpened ? 'flex' : 'none')};
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex['z-priority']};
  width: 100%;
`;

export const Modal = styled.div<ModalProps>`
  ${({ hasBackground, theme }) =>
    hasBackground
      ? `
    background-color: ${modal.backgroundColor};
    border-radius: ${modal.radius};
    display: none;
    max-height: 100vh;
    max-width: 650px;
    min-height: 200px;
    overflow: auto;
    padding: ${modal.mobile.padding};
    width: 100%;
    ${theme.breakpoints.upMedium} {
      padding: ${modal.desktop.padding};
    }`
      : null}

  ${({ isOpened }) =>
    isOpened
      ? `
      display: flex;
      flex-wrap: wrap;
      opacity: 1;
    `
      : null}

  animation: ${({ isLoaderModal }) => (isLoaderModal ? 'null' : 'moveOpen 0.5s ease-out')};
  margin: ${({ isLoaderModal, theme }) => (isLoaderModal ? '0' : theme.spacing.m)};

  @keyframes moveOpen {
    from {
      transform: translate(0, -100%);
      opacity: 0;
    }
    to {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
`;

export const ModalContent = styled(Column)<{ align: 'left' | 'center' | 'right' }>`
  text-align: ${({ align }) => `${align}`};
`;

export const ModalIllustration = styled.img`
  height: ${({ theme }) => `${theme.sizes.images.l}px`};
  width: ${({ theme }) => `${theme.sizes.images.l}px`};

  ${({ theme }) => theme.breakpoints.upTablet} {
    height: ${({ theme }) => `${theme.sizes.images.xl}px`};
    width: ${({ theme }) => `${theme.sizes.images.xl}px`};
  }
`;

export const ModalFooter = styled(Column)`
  margin-top: ${({ theme }) => theme.spacing.m};
`;

export const ModalFooterButtons = styled(Row)`
  max-width: 460px;
  margin: ${({ theme }) => theme.spacing.m} auto 0;

  a,
  button {
    &:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing.m};
    }

    &:last-child {
      margin-left: 0;
    }

    ${({ theme }) => theme.breakpoints.upMedium} {
      &:not(:last-child) {
        margin-bottom: 0;
      }

      &:last-child {
        margin-left: ${({ theme }) => theme.spacing.m};
      }
    }
  }
`;
