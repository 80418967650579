import React from 'react';
import emotionNormalize from 'emotion-normalize';
import { css, Global } from '@emotion/react/macro';
import { resetCSS } from 'src/theme/global/global.reset-css';
import { fontFaces } from 'src/theme/global/global.font-face';
import { thirdParties } from 'src/theme/global/global.third-parties';
import { inputTextTheme } from 'src/theme/tokens/components/form/inputText';
import { scrollBarTheme } from 'src/theme/tokens/components/scrollbar';
import { appTheme } from 'src/theme/tokens/components/app';
import { semantical } from 'src/theme/tokens/semantical';

const globalStylesBuilder = () => css`
  ${fontFaces}
  ${emotionNormalize}
  ${resetCSS}
  body,
  html {
    color: ${appTheme.color};
    background: ${appTheme.background};
    display: flex;
    font-family: ${appTheme.font};
    font-size: 100%;
    flex-direction: column;
    height: 100vh;
    margin: 0;
    min-height: 100vh;
    padding: 0;
    scroll-behavior: smooth;
    text-rendering: geometricPrecision;
    touch-action: manipulation;
    -webkit-font-smoothing: antialiased;
    .tippy-popper .tippy-tooltip {
      background-color: ${semantical.colors.background.neutral.stronger};
      color: ${appTheme.color};
      .arrow-regular {
        border-top-color: ${semantical.colors.background.neutral.stronger} !important;
      }
    }
  }

  a,
  button {
    &:hover {
      cursor: pointer;
    }
  }

  form {
    width: 100%;
  }

  /* clears the ‘X’ from Chrome */

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input:-webkit-autofill {
    -webkit-background-clip: text;
    -webkit-text-fill-color: ${inputTextTheme.colors.text.default};
    caret-color: ${inputTextTheme.colors.text.default};
    -webkit-box-shadow: 0 0 0 1000px ${inputTextTheme.colors.background.default} inset;
  }

  ::-webkit-scrollbar {
    width: ${scrollBarTheme.width};
    height: ${scrollBarTheme.height};
  }

  ::-webkit-scrollbar-track {
    background: ${scrollBarTheme.colors.track};
  }

  ::-webkit-scrollbar-thumb {
    background: ${scrollBarTheme.colors.thumb};
    border-radius: ${scrollBarTheme.borderRadius};
  }

  sup {
    top: -1em;
    font-size: 50%;
  }

  ${thirdParties}
`;

export const GlobalStyles = () => <Global styles={globalStylesBuilder()} />;
