import { FormattedMessage } from 'react-intl';
import { FormatMessageType } from 'src/components/utils/LanguageProvider/intl.types';
import messages from 'src/hocs/App/messages';

// eslint-disable-next-line no-mixed-operators
export const maxLengthV2 = (max: number) => (value: string, formatMessage: FormatMessageType) =>
  (value && value.length <= max) || formatMessage(messages.maxLength, { max });

export const isMobileRegex = /^(\+33|0033|0)(6|7)[0-9]{8}$/g;
export const isPhoneNumberRegex = /^(\+33|0033|0)[1-59][0-9]{8}$/g;
// the char 'ù' has been temporary removed from the list of allowed characters for names,
// after arbitrary and empirical testing, because it seems not to be supported
// by the call to https://api-so.ekwateur.fr/subscription_type/address
export const isName = /^[a-z àâäèéêëîïôöœûüç-]{2,60}$/i;
export const isStreetNumber = /^\d+(\s?[A-z]{0,5})?$/i;
export const isIbanRegex = /^[a-z]{2}[0-9]{2}[a-z0-9]{4}[0-9]{7}([a-z0-9]?){0,16}$/i;
export const emailRegex =
  /^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)*$/i;
export const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/g;

export const required = (value?: string) =>
  value ? undefined : <FormattedMessage {...messages.required} />;

export const maxLength = (max: number) => (value?: string) =>
  value && value.length > max ? (
    <FormattedMessage {...messages.maxLength} values={{ max }} />
  ) : null;

export const numbersOnly = (event: KeyboardEvent) => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);
  if (!/^[0-9]$/i.test(keyValue)) {
    event.preventDefault();
  }
};
