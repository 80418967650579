// App UI
import appHeaderLogo from './images/app/header-logo.svg';
import appBodyBackground from './images/app/body-background.webp';
import appCheckIcon from './images/app/check.svg';
import appMaintenance from './images/app/maintenance.svg';
import appValidation from './images/app/validation.svg';

// Activation process
import activationProcessApproved from './images/activation-process/approved.svg';
import activationProcessInProgress from './images/activation-process/in-progress.svg';
import activationProcessPending from './images/activation-process/pending.svg';
import activationProcessStarted from './images/activation-process/started.svg';

// Billing
import monthlyBillingThumb from './images/billing/monthly-billing-thumb.svg';
import monthlySeasonalBillingDisabled from './images/billing/monthly-seasonal-billing-disabled.svg';
import monthlySeasonalBillingEnabled from './images/billing/monthly-seasonal-billing-enabled.svg';
import monthlyAnnualBillingDisabled from './images/billing/monthly-smooth-billing-disabled.svg';
import monthlyAnnualBillingEnabled from './images/billing/monthly-smooth-billing-enabled.svg';
import openPadlock from './images/billing/open-padlock.svg';
import seasonalSmoothChart from './images/billing/seasonal-smooth-chart.svg';
import fixedPrice from './images/billing/fixed-price.svg';
import variablePrice from './images/billing/variable-price.svg';

// Carbon Impact
import carbonImpactComparisonReference from './images/carbon-impact/comparison-reference.svg';
import carbonPositiveImpact from './images/carbon-impact/positive-impact.svg';

// Customer
import customerPresent from './images/customer/present.svg';
import customerProfile from './images/customer/profile.svg';
import customerContact from './images/customer/contact.svg';
import customerContactSecondary from './images/customer/contact-secondary.svg';
import customerSuccess from './images/customer/success-primary.svg';
import customerSuccessAlternative from './images/customer/success-secondary.svg';
import customerMovingIn from './images/customer/moving-in.svg';
import customerSupplierChange from './images/customer/supplier-change.svg';
import customerHousing from './images/customer/housing.svg';
import mail from './images/customer/mail.svg';

// Customer > pro
import professionalCustomerSupplierChange from './images/customer/housing.svg';

// Energy > Distributor
import electricityDistributionSystemOperatorLogo from './images/energy/distributor/enedis/logo.svg';
import changeOfferTariff from './images/energy/distributor/enedis/change-tariff-offer.svg';
import gasDistributionSystemOperatorLogo from './images/energy/distributor/grdf/logo.svg';
import firstCommissioning from './images/energy/distributor/first-commissioning.svg';

// Energy > Supplier
import edfInvoiceHelper from './images/energy/supplier/edf/invoice-helper.png';
import eniInvoiceHelper from './images/energy/supplier/eni/invoice-helper.png';
import engieInvoiceHelper from './images/energy/supplier/engie/invoice-helper.png';

// Energy > Type
import energyExtremeConsumption from './images/energy/type/extreme-consumption.svg';

// Energy > Type > Electricity
import electricityIcon from './images/energy/type/electricity/electricity-icon.svg';
import electricityPrimary from './images/energy/type/electricity/electricity-primary.svg';
import electricitySecondary from './images/energy/type/electricity/electricity-secondary.svg';
import electricityExtremeConsumption from './images/energy/type/electricity/extreme-consumption.svg';
import electricityMobilityConsumptionComparison from './images/energy/type/electricity/mobility/consumption-comparison.svg';
import europeanElectricityFlag from './images/energy/type/electricity/flag-eu.png';
import frenchElectricityFlag from './images/energy/type/electricity/flag-fr.png';

// Energy > Type > Electricity > PowerStations
import selectedBasicPowerStation from './images/energy/type/electricity/power-stations/selected-basic-power-station.svg';
import unselectedBasicPowerStation from './images/energy/type/electricity/power-stations/unselected-basic-power-station.svg';
import selectedConnectedPowerStation from './images/energy/type/electricity/power-stations/selected-connected-power-station.svg';
import unselectedConnectedPowerStation from './images/energy/type/electricity/power-stations/unselected-connected-power-station.svg';

// Energy > Type > Gas
import gasIcon from './images/energy/type/gas/gas-icon.svg';
import gasPrimary from './images/energy/type/gas/gas-primary.svg';
import gasSecondary from './images/energy/type/gas/gas-secondary.svg';
import fullyRenewableGas from './images/energy/type/gas/fully-renewable-gas.svg';
import partiallyRenewableGas from './images/energy/type/gas/partially-renewable-gas.svg';
import gasMeterNotCommunicating from './images/energy/type/gas/meter/not-communicating.svg';
import gasSmartMeter from './images/energy/type/gas/meter/smart.svg';

// Energy: Meter
import electricityElectronicalMeterRegistrationNumber from './images/energy/type/electricity/meter/electronical-registration-number.svg';
import electricityHighLow from './images/energy/type/electricity/mobility/high-low.svg';
import electricitySmartMeter from './images/energy/type/electricity/meter/smart.svg';
import electricitySmartMeterAlternative from './images/energy/type/electricity/meter/electricity-meter-alternative.svg';

// Payment
import creditCardLogoList from './images/payment/credit-card-logo-list.svg';
import paymentProviderLogo from './images/payment/provider-logo.svg';
import paymentComplianceLogo from './images/payment/compliance-logo.png';
import paymentSecurityLogo from './images/payment/security-logo.png';

// Services
import serviceNotification from './images/service/notification.svg';
import boilerService from './images/service/boiler.svg';
import diffuseFadingService from './images/service/diffuse-fading.svg';
import nightWatchService from './images/service/nightwatch.svg';

// Errors
import genericError from './images/error/generic.svg';
import networkError from './images/error/network.svg';
import incompatibleOfferError from './images/error/incompatible-offer.svg';
import invalidOptionError from './images/error/invalid-options.svg';
import podAddressMismatchError from './images/error/pod-address-mismatch.svg';

// Estimator
import estimatorLodgment from './images/estimator/lodgment.svg';
import estimatorUsage from './images/estimator/usage.svg';
import estimatorHouseMeterEquipment from './images/estimator/house-meter-equipment.svg';
// @TODO replace apartment image by a new one when we have it
import estimatorApartmentMeterEquipment from './images/estimator/house-meter-equipment.svg';
import heatpump from './images/estimator/heat-pump.svg';
import convector from './images/estimator/convector.svg';
import inertia from './images/estimator/inertia.svg';

// GroupPurchase
import groupPurchaseLogo from './images/group-purchase/header-logo-selectra.png';

export const images = {
  appHeaderLogo,
  appBodyBackground,
  appCheckIcon,
  appMaintenance,
  appValidation,
  activationProcessStarted,
  activationProcessPending,
  activationProcessInProgress,
  activationProcessApproved,
  boilerService,
  carbonImpactComparisonReference,
  carbonPositiveImpact,
  changeOfferTariff,
  networkError,
  creditCardLogoList,
  customerContact,
  customerHousing,
  customerPresent,
  customerProfile,
  customerSuccess,
  customerSuccessAlternative,
  customerMovingIn,
  customerSupplierChange,
  diffuseFadingService,
  edfInvoiceHelper,
  eniInvoiceHelper,
  engieInvoiceHelper,
  energyExtremeConsumption,
  electricityMobilityConsumptionComparison,
  europeanElectricityFlag,
  frenchElectricityFlag,
  electricityIcon,
  electricityPrimary,
  electricitySecondary,
  electricityDistributionSystemOperatorLogo,
  electricityExtremeConsumption,
  electricityHighLow,
  electricitySmartMeter,
  electricitySmartMeterAlternative,
  electricityElectronicalMeterRegistrationNumber,
  firstCommissioning,
  gasIcon,
  gasPrimary,
  gasSecondary,
  gasSmartMeter,
  fullyRenewableGas,
  partiallyRenewableGas,
  gasDistributionSystemOperatorLogo,
  genericError,
  incompatibleOfferError,
  invalidOptionError,
  estimatorLodgment,
  estimatorUsage,
  estimatorHouseMeterEquipment,
  estimatorApartmentMeterEquipment,
  heatpump,
  inertia,
  convector,
  mail,
  monthlyBillingThumb,
  monthlyAnnualBillingEnabled,
  monthlyAnnualBillingDisabled,
  monthlySeasonalBillingDisabled,
  monthlySeasonalBillingEnabled,
  nightWatchService,
  paymentComplianceLogo,
  paymentProviderLogo,
  paymentSecurityLogo,
  podAddressMismatchError,
  professionalCustomerSupplierChange,
  seasonalSmoothChart,
  fixedPrice,
  variablePrice,
  serviceNotification,
  selectedBasicPowerStation,
  selectedConnectedPowerStation,
  unselectedBasicPowerStation,
  unselectedConnectedPowerStation,
  openPadlock,
  customerContactSecondary,
  groupPurchaseLogo,
  gasMeterNotCommunicating,
};
