import { createSelector } from 'reselect';

import * as c from 'src/hocs/WithSubscription/constants';
import { AppStateType } from 'src/store/appstate.type';
import { MandateResponseType } from 'src/hocs/WithBilling/types';
import * as t from './types';

const selectSubscription = (state: AppStateType): t.SubscriptionReducerType =>
  state[c.WITHSUBSCRIPTION];

export const selectCurrentSubscription = createSelector(
  selectSubscription,
  (substate): t.SubscriptionResponseType => {
    if (!substate?.subscription) return null;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isMandateSignature, ...otherProperties } = substate.subscription;
    return otherProperties;
  },
);

export const selectCurrentFullSubscription = createSelector(
  selectSubscription,
  (substate): t.SubscriptionResponseType => {
    if (!substate?.subscription) return null;
    return substate.subscription;
  },
);

export const makeSelectSubscription = () => selectCurrentSubscription;
export const makeSelectFullSubscription = () => selectCurrentFullSubscription;

export const selectActivationSteps = createSelector(
  selectSubscription,
  (substate) => substate.activationSteps || [],
);

export const selectSubscriptionId = createSelector(
  selectSubscription,
  (substate) => substate && substate.subscription && substate.subscription?.id,
);

export const makeSelectSubscriptionId = () => selectSubscriptionId;

export const selectSubscriptionLoading = createSelector(
  selectSubscription,
  (substate) => substate?.loading,
);

export const makeSelectIsSubscriptionLoading = () => selectSubscriptionLoading;

export const makeSelectMandateFromSubscription = () =>
  createSelector(selectSubscription, ({ subscription }): MandateResponseType => ({
    id: '',
    iban: subscription?.iban || '',
    bic: subscription?.bic || '',
    mandateId: subscription?.mandateId || '',
    levyType: 'RECUR',
    extId: '',
    bankName: '',
    shopId: '',
  }));

export const selectInvoiceFrequency = createSelector(
  selectSubscription,
  (substate) => substate.subscription?.invoiceFrequency || null,
);
export const makeSelectInvoiceFrequency = () => selectInvoiceFrequency;
