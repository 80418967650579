import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getInputPropsFromName } from 'src/utils/form/form.helper';
import { FormDataType } from 'src/utils/form/form.helper.types';
import Input from 'src/components/ui/inputs/input/Input';
import Heading from 'src/components/ui/typography/heading/Heading';
import Text from 'src/components/ui/typography/text/Text';
import Button from 'src/components/ui/button/Button';
import Row from 'src/components/ui/layouts/deprecated/row/Row';
import Column from 'src/components/ui/layouts/deprecated/column/Column';
import { Loader } from 'src/components/ui/loader/Loader';
import { usePauseSubscription } from 'src/hocs/WithSubscriptionNew/hooks';
import { SUBSCRIPTION_FIELDS } from 'src/hocs/WithSubscriptionNew/constants';
import {
  selectCurrentSubscription,
  selectSubscriptionLoading,
} from 'src/hocs/WithSubscriptionNew/selectors';
import { makeSelectFormField } from 'src/hocs/WithForm/selectors';
import { FORM_NAMES } from 'src/hocs/WithForm/constants';
import { GET_MANDATE_FIELDS } from 'src/hocs/WithBilling/constants';
import { useGetMandate } from 'src/hocs/WithBilling/hooks';
import { resetErrorNewAction } from 'src/hocs/App/actions';
import useIsDesktop from 'src/hooks/useIsDesktop';
import { useIntlMessage } from 'src/components/utils/LanguageProvider/intl.helpers';
import { PauseSubscriptionFormSchema } from '../PauseSubscriptionForm/PauseSubscriptionForm.schema';

export interface PauseSubscriptionFormType {
  [SUBSCRIPTION_FIELDS.EMAIL]: string;
}

export const ApiPayTimeoutForm = () => {
  // hooks
  const { intlMessage } = useIntlMessage();
  const isDesktop = useIsDesktop();
  const [hasSaved, setHasSaved] = useState(null);
  const fetchPauseSubscription = usePauseSubscription();
  const dispatch = useDispatch();
  const subscription = useSelector(selectCurrentSubscription);
  const loading = useSelector(selectSubscriptionLoading);
  const pauseSubscriptionForm = useForm<PauseSubscriptionFormType>({
    mode: 'onSubmit',
    resolver: yupResolver(PauseSubscriptionFormSchema()),
  });
  const iban = useSelector(makeSelectFormField(FORM_NAMES.billing, GET_MANDATE_FIELDS.IBAN));
  const { fetchUrl: fetchMandate } = useGetMandate(iban);

  // constants
  const formData: FormDataType = {
    ...pauseSubscriptionForm,
    defaultValues: {
      [SUBSCRIPTION_FIELDS.EMAIL]: subscription?.email,
    },
  };
  const onSubmit = async (values: PauseSubscriptionFormType) => {
    await fetchPauseSubscription(values?.email);
    setHasSaved(values?.email);
  };
  const closeModal = () => dispatch(resetErrorNewAction());
  const retryRequest = () => {
    fetchMandate();
    closeModal();
  };

  return (
    <>
      {hasSaved && (
        <>
          <Heading type="h4">{intlMessage('app.SaveProgression.titleStep2')}</Heading>
          <Text type="l">{intlMessage('app.SaveProgression.subTitleStep2')}</Text>
          <Text>{hasSaved}</Text>
          <br />
          <Button onClick={closeModal} label={intlMessage('shared.i18n.ok')} />
        </>
      )}
      {!hasSaved && (
        <>
          <Text type="l">{intlMessage('app.pauseSubscriptionForm.apiPayTimeout')}</Text>
          <br />
          <form onSubmit={formData.handleSubmit(onSubmit)}>
            <Column bottom={16}>
              <Input
                {...getInputPropsFromName(SUBSCRIPTION_FIELDS.EMAIL, formData)}
                icon={loading && <Loader />}
              />
            </Column>
            <Row align="around" bottom={0}>
              <Row tp={2} md={2} lg={6} bottom={!isDesktop && 10}>
                <Button
                  type="submit"
                  disabled={loading}
                  label={intlMessage('app.pauseSubscriptionForm.saveSubscription')}
                />
              </Row>
              <Row tp={2} md={2} lg={6} bottom={0}>
                <Button
                  onClick={retryRequest}
                  disabled={loading}
                  label={intlMessage('app.pauseSubscriptionForm.retry')}
                />
              </Row>
            </Row>
          </form>
        </>
      )}
    </>
  );
};
